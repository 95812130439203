import React, { Component } from "react";
import ReactGA from "react-ga";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

export default class NavBar extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  onNavigate() {
    ReactGA.event({
      category: "Navigation",
      action: "Clicked Link",
    });
  }
  render() {
    return (
      <Navbar color="dark" fixed="top" className="navbar-dark navbar-expand-md">
        <NavbarToggler onClick={this.toggle} className="mr-2" />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="mx-auto" navbar>
            <NavItem>
              <NavLink className="js-scroll-trigger" href="/#about">
                Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="js-scroll-trigger" href="/#events">
                Events
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="js-scroll-trigger" href="/past-events">
                Past Events
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="js-scroll-trigger" href="/podcasts">
                Podcast
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="js-scroll-trigger" href="/#join">
                Join
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="js-scroll-trigger" href="/blog">
                Blog
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="js-scroll-trigger" href="/board">
                Board
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="js-scroll-trigger" href="/#partners">
                Partners
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="js-scroll-trigger" href="/#contact">
                Contact us
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}
