import React, { Component } from "react";
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PageView, initGA } from "./components/Tracking";
import Layout from "./components/Layout";
import Home from "./components/Home";
import NoPage from "./components/NoPage";
import Blog from "./components/Blog";
import Podcasts from "./components/Podcasts";
import Board from "./components/Board";
import PastEvents from "./components/PastEvents";

class App extends Component {
  componentDidMount() {
    initGA("UA-69964850-2");
    PageView();
    this.setState({ modalVisible: true });
  }

  render() {
    // test
    const isProduction = process.env.NODE_ENV === 'production';
    return (
      <CacheBuster
        currentVersion={version}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
      >
        <div>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/board" element={<Board />} />
                <Route path="/podcasts" element={<Podcasts />} />
                <Route path="/past-events" element={<PastEvents />}>
                  <Route index element={<PastEvents />} />
                  <Route path=":folderName" element={<PastEvents />} />
                </Route>
                <Route path="*" element={<NoPage />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </div>
      </CacheBuster>
    );
  }
}

export default App;