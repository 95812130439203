import React from 'react';
import { Card, CardBody, CardLink, CardTitle, CardSubtitle, CardText, CardImg, Row, Col} from 'reactstrap';
import { useParams, Link } from 'react-router-dom';
import { ReactComponent as DowloadSVG } from "../images/icons/download.svg";

const PastEvents = () => {
  const { folderName = '' } = useParams();

  const eventImageFolders = () => {
    return [
      {
        title: "Summer Social 2024 @ Homeboy Bar",
        date: "24/07/2024",
        name: "summersocial2024",
        desc: "Our annual Summer Social event, this year at Homeboy Bar, focused around Irish hospitality in London.",
        images: require.context(
          "../images/events/summersocial2024",
          false,
          /\.(png|jpe?g|svg)$/
        ),
      },
      {
        title: "Finding Your Place in the World of Work",
        date: "18/04/2024",
        name: "embassy2024",
        desc: "An event in the Embassy of Ireland to muse on how to find our place in the world of work",
        images: require.context(
          "../images/events/embassy2024",
          false,
          /\.(png|jpe?g|svg)$/
        ),
      },
      {
        title: "IWD 2024 - Breaking Barriers: Inspiring Irish Women in Business, Tech, Financial Services",
        date: "21/03/2024",
        name: "breakingbarriers",
        desc: "IWD event: 'Inspiring Irish Women in Business, Finance, Tech & the Arts .' Empowering talks, knowledge sharing & a few drinks.",
        images: require.context(
          "../images/events/breakingbarriers",
          false,
          /\.(png|jpe?g|svg)$/
        ),
      },
      {
        title: "Green Employees: Building a Sustainable Workforce",
        date: "22/02/2024",
        name: "esg",
        desc: "A discussion around the role of employees in helping their companies be leaders in the race to net zero.",
        images: require.context(
          "../images/events/esg",
          false,
          /\.(png|jpe?g|svg)$/
        ),
      },
      {
        title: "Belonging | An evening with LIBS and Seanchoíche",
        date: "05/10/2023",
        name: "seanchoiche",
        desc: "A special evening of storytelling at the London Irish Centre.",
        images: require.context(
          "../images/events/seanchoiche",
          false,
          /\.(png|jpe?g|svg)$/
        ),
      },
      {
        title: "Live Women's Six Nations with Sports Broadcaster Sarah Mulkerrins",
        date: "25/03/2023",
        name: "womens6nations",
        desc: "Join us and Irish sports broadcaster Sarah Mulkerrins for a few drinks as we watch Ireland kick off the Women's Six Nations against Wales .",
        images: require.context(
          "../images/events/womens6nations",
          false,
          /\.(png|jpe?g|svg)$/
        ),
      },
      {
        title: "Thriving in Times of Turbulence: Guidance on the Evolving Job Market",
        date: "06/07/2023",
        name: "turbulence",
        desc: "Thriving in Times of Turbulence is a live panel discussion between employment experts, in association with Morgan McKinley.",
        images: require.context(
          "../images/events/turbulence",
          false,
          /\.(png|jpe?g|svg)$/
        ),
      },
      {
        title: "LIBS Summer Social",
        date: "06/07/2022",
        name: "summersocial",
        desc: "With a glass in hand, this informal gathering was the perfect opportunity to mingle with friends old and new. ",
        images: require.context(
          "../images/events/summersocial",
          false,
          /\.(png|jpe?g|svg)$/
        ),
      },
      {
        title: "A celebration of Irish sporting heritage in London",
        date: "16/09/2021",
        name: "embassy2021",
        desc: "Celebrate how the Irish diaspora in London has united and grown into a strong community, both socially and economically, through a common interest and love for sport.",
        images: require.context(
          "../images/events/embassy2021",
          false,
          /\.(png|jpe?g|svg)$/
        ),
      },
      {
        title: "Irish Innovation: Celebrating the Irish Tech Startup Community",
        date: " 30/10/2019",
        name: "startupevent",
        desc: "An evening of celebration of the Irish startup community.",
        images: require.context(
          "../images/events/startupevent",
          false,
          /\.(png|jpe?g|svg)$/
        ),
      },
    ];
  };

  const getFolder = (folderName) => {
    return eventImageFolders().find(f => f.name === folderName);
  }

  const eventPhotos = (folder) => {
    const photos = [];
    const imageFolder = folder.images;
    imageFolder.keys().forEach((key, index) => {
      const imageModule = imageFolder(key);
      const src = imageModule.default || imageModule;
      const folderName = folder.title.replace(/ /g, "-").toLowerCase();
      photos.push({
        src: src,
        altText: `${folderName} event`
      });
    });

    return photos;
  };

  return (
    <div>
      <section>
        <div className="container">
          <h2>{folderName ? (
            <Link to={'/past-events'}>
              <i class="fa fa-arrow-left mr-1 text-body" aria-hidden="true"></i></Link>) : ''} {folderName ? getFolder(folderName).title : 'Past Events'}
          </h2>
          {folderName === '' && (
            <Row>
              {eventImageFolders().map((folder, index) => (
                <Col sm="4" key={index} className="mb-4">
                  <Card>
                    <CardImg top className="card-img-top" width="100%" src={eventPhotos(folder)[0].src} alt={`Image for ${folder.title}`} />
                    <CardBody className="card-body">
                      <CardTitle tag="h5">{folder.title}</CardTitle>
                      <CardText>{folder.desc}</CardText>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">{folder.date}</CardSubtitle>
                      <Link className="btn text-light bg-libs-green-3" to={`/past-events/${folder.name}`}>View Album</Link>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
          {folderName !== '' && (
            <Row>
              {eventPhotos(getFolder(folderName)).map((photo, index) => (
                <Col sm="4" key={index} className="mb-4">
                  <Card>
                    <CardImg top className="card-img-top" width="100%" src={photo.src} />
                    <CardLink
                      href={photo.src}
                      download={`image-${index}.jpg`}
                      className="image-download"
                    >
                      <DowloadSVG style={{ width: "2rem", height: "2rem", fill: "white" }} />
                    </CardLink>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
        </div>
      </section>
    </div>
  );
};

export default PastEvents;
