import React, { Component } from "react";

export default class Events extends Component {
  constructor(props) {
    super(props);
    fetch(
      "https://www.eventbriteapi.com/v3/organizations/168892618116/events/",
      {
        method: "GET",
        headers: {
          Authorization: "Bearer CFPDE7LKYPO3OPMNXA44",
        },
      }
    )
      .then(async (response) => {
        const data = await response.json();

        let events = [];
        for (let i = 0; i < 8; i++) {
          events.push({
            url: data.events[i].url,
            name: data.events[i].name.text,
          });
        }
        this.setState({ events: events });
      })
      .catch(function (error) {
        console.log("Error loading events", error);
      });
  }

  render() {
    return (
      <div>
        <section className="bg-libs-blue-1">
          <div className="container">
            <div className="row">
              <div className="col">
                <h2>Next Event</h2>

                <h4>
                  LIBS Summer Social @ Homeboy Bar{" "}
                </h4>
                <ul>
                  <li>Date: Wed 24 July</li>
                  <li>Time: 17:00 </li>
                  <li>
                    Venue: Homeboy Bar, 108 Essex Road, London, N1 8LX

                  </li>
                </ul>

                <p>Join us for our Summer Social event on July 24th at Homeboy Bar in Angel! Homeboy represents modern Irish hospitality!</p>
                <p>We're excited to host a lively fireside chat featuring prominent figures like Cassie Stokes and Aaron Wall, alongside other influential leaders from London's dynamic hospitality industry.</p>
                <p>It's an evening not to be missed, filled with insightful discussions, networking opportunities, and great vibes.</p>

                <p>
                  <a href="https://www.eventbrite.co.uk/e/libs-summer-social-homeboy-bar-tickets-936496786097">
                    Tickets
                  </a>{" "}
                  are free but limited so secure your spot now
                </p>

                {/*<h4>Previous Events</h4>
                            <ul>
                                {this.state.events.map((event, index) => {
                                    return <li key={index}><a href={event.url}>{event.name}</a></li>
                                })}
                            </ul>*/}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
